import { Delete } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/joy";
import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../../Config/API_URLS";
import axiosInstance from "../../../Config/axios";
import CustomDiv from "../../../Shared/CustomDiv";
import Text from "../../../Shared/Text";

const Wishlist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const myWishList = () => {
    setIsLoading(true);
    axiosInstance
      .get(API_URLS.myWishList)
      .then((response) => {
        setData(response.data.data.wishlist_product_list);
        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const removeWishList = (id) => {
    axiosInstance
      .post(API_URLS.removeWishList, { wishlist_id: id })
      .then((response) => {
        myWishList();
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  useEffect(
    () => {
      myWishList();
    },
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return isLoading ? (
    <>
      <CustomDiv className="flex h-full justify-center items-center">
        <CircularProgress size="lg" color="primary" />
      </CustomDiv>
    </>
  ) : (
    <CustomDiv className="flex flex-col gap-5">
      <Text className="text-xl font-semibold">Wishlist</Text>
      {data?.map((wishlist) => {
        return (
          <>
            <CustomDiv className="flex justify-between items-center">
              <span className="flex items-center">
                <img
                  src={wishlist.product_image}
                  alt=""
                  className="w-20 h-20"
                />
                <span>
                  <Text className="block w-40 overflow-hidden whitespace-nowrap text-ellipsis">
                    {wishlist.title}
                  </Text>
                  <Text>{wishlist.main_category}</Text>
                  <Text>₹{wishlist.price}</Text>
                </span>
              </span>
              <IconButton
                color="primary"
                onClick={() => removeWishList(wishlist.wishlist_id)}
              >
                <Delete color="primary" />
              </IconButton>
            </CustomDiv>
          </>
        );
      })}
    </CustomDiv>
  );
};
export default Wishlist;
