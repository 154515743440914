import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/API_URLS";
import bcde from "../../../Assets/bcde.webp";
import { useNavigate } from "react-router-dom";
import { bannerFn } from "../../../Services/Banner";
import { useMutation } from "react-query";
import CustomDiv from "../../../Shared/CustomDiv";
import { Divider } from "@mui/joy";
import Text from "../../../Shared/Text";

const HomeProduct = () => {
  const [data, setData] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [dataaa, setDataaa] = useState([]);
  const [dataaaa, setDataaaa] = useState([]);
  const [banners, setBanners] = useState([]);
  const navigate = useNavigate();

  const Product = () => {
    const reqBody = {
      add_quantity: 1,
    };
    axiosInstance.get(API_URLS.productList, reqBody).then((response) => {
      setData(response.data.data);
    });
  };

  const Products = () => {
    const reqBody = {
      add_quantity: 1,
    };
    axiosInstance.post(API_URLS.topRated, reqBody).then((response) => {
      setDataa(response.data.data.product_list);

    });
  };

  const Productss = () => {
    const reqBody = {
      add_quantity: 1,
    };
    axiosInstance.post(API_URLS.recentlyAdded, reqBody).then((response) => {
      setDataaa(response.data.data.product_list);

    });

  };

  const Productsss = () => {
    const reqBody = {
      add_quantity: 1,
    };
    axiosInstance.post(API_URLS.mostViewed, reqBody).then((response) => {
      setDataaaa(response.data.data.product_list);

    });
  };

  const { mutate: banner } = useMutation(bannerFn, {
    onSuccess: (response) => {
      setBanners(response.data.data);
    },
  });

  useEffect(() => {
    Product();
    Products();
    Productss();
    Productsss();
    banner();
  }, []);

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:mt-5 lg:text-3xl">
          <p className=""> {data[0]?.Meta_Title}</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {data[0]?.product_deatils?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md hover:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:mt-5 lg:text-3xl">
          <p className=""> {data[1]?.Meta_Title}</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {data[1]?.product_deatils?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md hover:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:mt-5 lg:text-3xl">
          <p className=""> {data[2]?.Meta_Title}</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {data[2]?.product_deatils?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md hover:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <CustomDiv className="flex flex-col-reverse gap-10 justify-center items-center my-4 bg-[#F2F8F9] p-4 ">
          {banners?.slice(0, 1).map((banner) => {
            return (
              <div className="flex flex-col gap-2 lg:gap-5">
                <Divider textAlign="left" className="font-bold lg:text-xl">
                  <Text>{banner.brand_name}</Text>
                </Divider>
                <div className="flex gap-2 lg:gap-10">
                  <img src={banner.image} alt="" />
                </div>
              </div>
            );
          })}
        </CustomDiv>

        <div className="flex justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:mt-5 lg:text-3xl">
          <p className="">TOP RATED PRODUCTS</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {dataa?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.variant_image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md hover:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <CustomDiv className="flex flex-col-reverse gap-10 justify-center items-center my-4 bg-[#F2F8F9] p-4 ">
          {banners?.slice(1, 2).map((banner) => {
            return (
              <div className="flex flex-col gap-2 lg:gap-5">
                <Divider textAlign="left" className="font-bold lg:text-xl">
                  <Text>{banner.brand_name}</Text>
                </Divider>
                <div className="flex gap-2 lg:gap-10">
                  <img src={banner.image} alt="" />
                </div>
              </div>
            );
          })}
        </CustomDiv>

        <div className="flex justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:text-3xl lg:mt-5">
          <p className="">RECENTLY ADDED PRODUCTS</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {dataaa?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md hover:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>

        <CustomDiv className="flex flex-col-reverse gap-10 justify-center items-center my-4 bg-[#F2F8F9] p-4 ">
          {banners?.slice(0, 1).map((banner) => {
            return (
              <div className="flex flex-col gap-2 lg:gap-5">
                <Divider textAlign="left" className="font-bold lg:text-xl">
                  <Text>{banner.brand_name}</Text>
                </Divider>
                <div className="flex gap-2 lg:gap-10">
                  <img src={banner.image} alt="" />
                </div>
              </div>
            );
          })}
        </CustomDiv>

        <div className="flex justify-between p-8 text-xl font-bold text-center text-gray-700 bg-gray-200 lg:text-3xl lg:mt-5">
          <p className="">MOST VIEWED PRODUCTS</p>
        </div>
        <div className="grid grid-cols-2 gap-3 px-4 lg:grid-cols-5 xl:grid-cols-6 md:grid-cols-3 lg:gap-2">
          {dataaaa?.slice(0, 24).map((product) => {
            return (
              <div
                className=" group-item group object-cover lg:h-[440px] h-fit lg:w-[215px] duration-200 lg:my-5 lg:-mb-5 hover:border border-black bg-white cursor-pointer"
                // onClick={() => navigate(`/product/${product?.id}`)}
                onClick={() => navigate(`/product/${product?.id}/${product?.variant_id}`)}
              >
                <span className="group ">
                  <img
                    src={product.image}
                    alt=""
                    className=" lg:w-[215px] lg:h-[300px] object-cover"
                  />
                  <div className="bg-white text-center object-cover lg:h-[75px] h-[95px] p-2 ">
                    <p className="text-sm font-bold lg:text-md ">
                      {" "}
                      {product.Meta_Title}
                    </p>
                    <p className="text-lg font-semibold lg:text-xl">
                      ₹{product.actual_price}
                    </p>
                  </div>
                  <span className="invisible group-hover:visible group-hover:scale-y-110">
                    <div className="flex justify-center gap-1">
                      <button className="bg-[#A0ABD5] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#778c66] w-4 border border-gray-600 h-4 rounded-full"></button>
                      <button className="bg-[#D490BB] w-4 border border-gray-600 h-4 rounded-full"></button>
                    </div>
                    <div className="flex flex-col items-center justify-center p-1 px-4 m-2 font-semibold text-white transition duration-200 bg-gray-800 rounded-md lg:bg-black">
                      <button>ADD TO BAG</button>
                    </div>
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HomeProduct;
