import { Add, Edit } from "@mui/icons-material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { useFormik } from "formik";
import { useEffect } from "react";
import React, { useState } from "react";
import { API_URLS } from "../../../Config/API_URLS";
import axiosInstance from "../../../Config/axios";
import CustomButton from "../../../Shared/CustomButton";
import CustomDiv from "../../../Shared/CustomDiv";
import {
  MenuItem,
  TextField,
  FormControl,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Divider,
  Switch,
} from "@mui/material";
import Text from "../../../Shared/Text";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { paymentReceiptFn } from "../../../Services/PaymentReceipt";
import { checkOutFn } from "../../../Services/CheckOut";
import { myAddressList } from "../../../Services/AddressList";
import { codCheckoutFn } from "../../../Services/CODCheckout";
import accpt from "../../../Assets/accept.png";
import { addAddressSchema } from "../../../Schemas";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const SelectAddress = ({ setPage, totalAmount, setOrderDetail ,items , item }) => {
  const [opens, setOpens] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressID, setAddressID] = useState(null);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [value, setValue] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [method, setMethod] = useState("COD");
  const [pyamtList, setPyamtList] = useState([])
  const [toggle, setToggle] = useState(false)

  const handlePaymentMethod = (event) => {
    setMethod(event.target.value);
  };

  const handleUpdate = (addressId) => {
    setOpen(true);
    setAddressID(addressId);
  };

  const handleAddAddress = () => {
    setOpen(true);
    setAddressID(null);
    setValue(null);
  };

  const handleNewAddAddress = () => {
    setOpens(true);
    setAddressID(null);
    setValue(null);
  }

  const btn = () => {
    setToggle(true)
  };

  const btnn = () => {
    setToggle(false)
  }

  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();
  const { data: addressListData } = useQuery(
    ["myAddressList"],
    () => myAddressList(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const countryList = () => {
    axiosInstance
      .get(API_URLS.countryList)
      .then((response) => {
        setCountry(response.data.data);
        stateList();
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const address = addressListData?.data?.data?.my_address_list?.filter(
    (data) => data.id === value
  );

  const initialValues = value
    ? {
      name: address?.[0]?.name,
      address_type: address?.[0]?.address_type,
      area: address?.[0]?.area,
      street_address: address?.[0]?.street_address,
      city_id: address?.[0]?.city_id,
      state_id: address?.[0]?.state_id,
      country_id: address?.[0]?.country_id,
      pincode: address?.[0]?.pincode,
      email: address?.[0]?.email,
      mobile_number: address?.[0]?.mobile_number,
      address: address?.[0]?.address,
      address_type3:address?.[0]?.is_same_as_billing_address === "True" ? "address" : "billing",
    }
    : {
      name: "",
      address_type: "",
      area: "",
      street_address: "",
      city_id: "",
      state_id: "",
      country_id: "",
      pincode: "",
      email: "",
      mobile_number: "",
      address: "",
    };

  const { handleChange, isValid, handleSubmit, values, errors, handleBlur } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addAddressSchema,
      enableReinitialize: true,

      onSubmit: (values, action) => {
        const reqBody = {
          name: values.name,
          address_id: addressID,
          address_type: values.address_type,
          address: values.address,
          area: values.area,
          street_address: values.street_address,
          city_id: values.city_id,
          state_id: values.state_id,
          country_id: values.country_id,
          pincode: values.pincode,
          email: values.email,
          mobile_number: values.mobile_number,
         
          is_same_as_billing_address:
          values.address_type3 === "address" ? "True" : "False",
        is_only_billing_address:
         "False",
        is_only_delivery_address:
          values.address_type3 === "billing" ? "True" : "False",
        };
        addressID
          ? axiosInstance
            .post(API_URLS.updateAddress, reqBody)
            .then((response) => {
              client.refetchQueries("myAddressList"); 
              enqueueSnackbar("Address Updated", { variant: "success" });
              setOpen(false);
            })
            .catch((error) => {
              enqueueSnackbar("Something went wrong..!", {
                variant: "error",
              });
            })
          : axiosInstance
            .post(API_URLS.addAddress, reqBody)
            .then((response) => {
              client.refetchQueries("myAddressList");
              enqueueSnackbar("Address Added", { variant: "success" });
              setOpens(false);
            })
            .catch((error) => {
              enqueueSnackbar("Something went wrong..!", {
                variant: "error",
              });
            });
        action.resetForm();
      },
    });

  useEffect(
    () => {
      open ? countryList() : emptyFunction();
    },
    // eslint-disable-next-line
    [open]
  );

  const stateList = () => {
    axiosInstance
      .post(API_URLS.stateList, { country_id: values.country_id })
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  const cityList = () => {
    axiosInstance
      .post(API_URLS.cityList, { state_id: values.state_id })
      .then((response) => {
        setCity(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  useEffect(
    () => {
      values.country_id !== "" ? stateList() : emptyFunction();
    },
    // eslint-disable-next-line
    [values.country_id]
  );
  useEffect(
    () => {
      values.state_id !== "" ? cityList() : emptyFunction();
    },
    // eslint-disable-next-line
    [values.state_id]
  );
  const { mutate: paymentReceipt } = useMutation(paymentReceiptFn, {
    onSuccess: (response) => {
      setReceipt(response.data?.payment_link)
      // setReceipt(response.data.data.payment_receipt[0]);
    },
  });

  const { mutate: checkOut, isLoading: isLoadingOnline } = useMutation(
    checkOutFn,
    {
      onSuccess: (response) => {
        setReceipt(response.data.data);
      },
    }
  );
  const { mutate: codCheckOut, isLoading: isLoadingCOD } = useMutation(
    codCheckoutFn,
    {
      onSuccess: (response) => {
        setPage(3);
      },
    }
  );

  const orderIdOnline = () => {
    paymentReceipt({
      address_id: value,
      total_amount: totalAmount,
      sub_total:items?.cart_subtotal,
discount:items?.cart_product_discount_price,
amount:items?.price,
coupon_id:items?.cart_coupon_code,
redirect_url:"https://srishtimedicalbookhouse.com/user-account",
items:item.map((i)=>({quantity:i?.cart_product_quantity,id:i?.variant_id,cart_id:i?.cart_product_id}))
    });
  };
  const orderIdCOD = () => {
    codCheckOut({
      address_id: value,
      coupon_id: "None",
    });
  };

  const RazorPay = () => {
    const options = {
      key: "rzp_test_vDJzs5kXvURgrY",
      // key: "rzp_test_5AJBhMjZsst5JG",
      currency: "INR",
      amount: totalAmount,
      name: "srishti medical book store",
      order_id: receipt?.razorpay_order_id,
      handler: function (response) {
        setOrderDetail(response);
        enqueueSnackbar("Order Placed", { variant: "success" });
        setPage(3);
        const reqbody = new FormData();
        reqbody.append("address_id", value);
        reqbody.append("coupon_id", "None");
        reqbody.append("payment_receipt_id", receipt?.payment_receipt_id);
        checkOut(reqbody);
        client.refetchQueries("myOrderList");
      },
      theme: {
        color: "#306BDE",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };


  const PaymentGateway = () => {
    axiosInstance
      .get(API_URLS.Store)
      .then((response) => {
        setPyamtList(response.data?.data?.store_data);
        stateList();
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  const ccAvnus = () => {
    window.location.href = receipt
  }

  const emptyFunction = () => { };
  useEffect(
    () => {
      receipt && method === "Online" ? ccAvnus() : PaymentGateway();
    },
    [receipt]
  );

  return paymentMethod ? (
    <>
      <CustomDiv className="flex flex-col items-center justify-center w-full gap-5">
        <Text className="text-xl font-semibold text-center">
          Select A Payment Method
        </Text>
        <CustomDiv className="flex flex-col gap-2 lg:h-[79vh] h-[75vh] w-full">
          <FormControl fullWidth>
            <RadioGroup
              value={method}
              onChange={handlePaymentMethod}
              className="gap-1 flex flex-col justify-center !w-full"
            >
              <FormControlLabel
                value="Online"
                control={<Radio />}
                label="Online"
                className="p-3 rounded border-2 !w-full shadow"
                onClick={btn}
              />

              <FormControlLabel
                value="COD"
                control={<Radio />}
                label="Cash On Delivery"
                className="p-3 rounded border-2 !w-full shadow"
                onClick={btnn}
              />

            </RadioGroup>

          </FormControl>

          {toggle === true ? <Text className="flex flex-col items-center justify-start w-full p-3 text-white bg-sky-400">
            <p
              className="py-1 "
              value={pyamtList?.is_store_payment_accepted_by}
            >
              Pay with {pyamtList?.is_store_payment_accepted_by}
            </p>
          </Text> : null}

        </CustomDiv>
        <span className="flex w-full gap-3 whitespace-nowrap">
          <CustomButton
            className="lg:w-full w-44"
            variant="outlined"
            onClick={() => setPaymentMethod(false)}
          >
            Back to Address
          </CustomButton>
          <CustomButton
            className="lg:w-full w-44"
            variant={isLoadingCOD || isLoadingOnline ? "outlined" : "contained"}
            onClick={() =>
              method === "Online" ? orderIdOnline() : orderIdCOD()
            }
          >
            {isLoadingCOD || isLoadingOnline ? (
              <CircularProgress size={25} />
            ) : (
              <span>Place Your Order</span>
            )}
          </CustomButton>
        </span>
      </CustomDiv>
    </>
  ) : (
    <CustomDiv className="flex flex-col gap-5 lg:w-auto">
      <Text className="text-xl font-semibold text-center">Select Address</Text>
      <Divider />
      <CustomDiv className="flex flex-col gap-2 lg:h-[79vh] h-[75vh] overflow-scroll">
        {addressListData?.data?.data?.my_address_list?.map((address) => {
          return (
            <CustomDiv
              className={classNames(
                "flex flex-col cursor-pointer hover:shadow-md transition-all duration-200 shadow p-4 !rounded",
                value === address.id ? "shadow-md" : ""
              )}
              onClick={() => setValue(address.id)}
            >
              <Text className="p-1 text-xs font-bold bg-gray-100 rounded-md">
                {
                  (address.is_same_as_billing_address === "True") && (address.is_only_billing_address === "True") ?
                    "Shipping & Billing Address"
                    :
                    <>
                      {address.is_same_as_billing_address === "True" ? "Shipping & Billing Address" : null}
                      {address.is_only_delivery_address === "True" ? "Shipping Address" : null}
                      {address.is_only_billing_address === "True" ? "Billing Address" : null}
                    </>
                }

              </Text>
              <span className="flex !text-xs justify-between items-end">
                {value === address.id ? (
                  <>
                    <RadioGroup row className="!flex !p-0 justify-around" name="radio-button-group">
                      <FormControlLabel value="billing" control={<Radio name="abcd" checked="checked" size="small" />} className="!text-xs text-gray-500" label="Billing Address Same" />
                      <FormControlLabel value="address" control={<Radio name="abcd" size="small" />} className="!text-xs text-gray-500" label="Other" onClick={() => handleNewAddAddress()} />
                    </RadioGroup>
                  </>
                ) : null}
              </span>
              <span className="flex justify-between">
                <Text className="font-semibold">{address.address_type}</Text>
                {value === address.id ? (
                  <img src={accpt} alt="" className="h-5" />
                ) : null}
              </span>
              <Text>
                {address.first_name} {address.last_name}
              </Text>

              <span className="flex items-end justify-between">

                <Text>
                  {address.address}, {address.area}, {address.street_address},
                  {address.city}, {address.state}, {address.country},
                  {address.pincode}
                </Text>
                {value === address.id ? (
                  <Edit
                    fontSize="small"
                    color="primary"
                    onClick={() => handleUpdate(Number(address?.id))}
                  />
                ) : null}
              </span>
            </CustomDiv>
          );
        })}
        <span
          onClick={() => handleAddAddress()}
          className="flex items-center !text-gray-600 cursor-pointer hover:shadow-md shadow p-9 rounded"
        >
          <Add />
          <Text > Add Address</Text>
        </span>
      </CustomDiv>


      <span className="flex w-full gap-5">
        <CustomButton
          variant="outlined"
          onClick={() => setPage(1)}
          className="!w-full !text-gray-900"
        >
          Back to cart
        </CustomButton>
        <CustomButton
          onClick={() => setPaymentMethod(true)}
          disabled={value ? false : true}
          className="!w-full"
        >
          Continue
        </CustomButton>
      </span>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        className="flex justify-center h-5/6 !outline-none my-auto"
      >
        <Sheet
          variant="outlined"
          className="lg:w-1/2 w-11/12 !outline-none p-8 rounded"
        >

          <ModalClose variant="outlined" />
          <form onSubmit={handleSubmit} className="h-full">
            <Text className="my-3 text-xl font-semibold">
              {!value ? "Add Address" : "Update Address"}
            </Text>
            <CustomDiv className="grid gap-5 pr-1 overflow-y-auto lg:grid-cols-2 h-5/6 lg:pr-0">
              <span className="w-full">
                <Text>Name*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="name"
                  name="name"
                  value={values["name"]}
                  onChange={handleChange}
                  error={errors["name"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["name"]}
                />
              </span>
              <span className="w-full">
                <Text>Address Type*</Text>
                <FormControl fullWidth>
                  <Select
                    id="address_type"
                    name="address_type"
                    size="small"
                    defaultValue={address?.[0]?.address_type}
                    color="primary"
                    value={values["address_type"]}
                    onChange={handleChange}
                    error={errors["address_type"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["address_type"]}
                  >
                    <MenuItem value={"Home"}>Home</MenuItem>
                    <MenuItem value={"Office"}>Office</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>Contact Number*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="mobile_number"
                  name="mobile_number"
                  value={values["mobile_number"]}
                  onChange={handleChange}
                  error={errors["mobile_number"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["mobile_number"]}
                />
              </span>
              <span className="w-full">
                <Text>Flat/House No./Compnay Name*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="address"
                  name="address"
                  value={values["address"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["address"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["address"]}
                />
              </span>
              <span className="w-full">
                <Text>Area/Landmark*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="area"
                  name="area"
                  value={values["area"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["area"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["area"]}
                />
              </span>

              <span className="w-full">
                <Text>Street/Sector/Village*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="street_address"
                  name="street_address"
                  value={values["street_address"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["street_address"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["street_address"]}
                />
              </span>
              <span className="w-full">
                <Text>Pincode*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="pincode"
                  name="pincode"
                  value={values["pincode"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["pincode"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["pincode"]}
                />
              </span>
              <span className="w-full">
                <Text>Email*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="email"
                  name="email"
                  value={values["email"]}
                  onChange={handleChange}
                  error={errors["email"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["email"]}
                />
              </span>

              <span className="w-full">
                <Text>Country*</Text>
                <FormControl fullWidth>
                  <Select
                    id="country_id"
                    size="small"
                    color="primary"
                    name="country_id"
                    value={values["country_id"]}
                    onChange={handleChange}
                    error={errors["country_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["country_id"]}
                  >
                    {country?.map((country) => {
                      return (
                        <MenuItem value={country.id}>
                          {country.country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>State*</Text>
                <FormControl fullWidth>
                  <Select
                    id="state_id"
                    name="state_id"
                    size="small"
                    color="primary"
                    value={values["state_id"]}
                    onChange={handleChange}
                    error={errors["state_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["state_id"]}
                  >
                    {state?.map((state) => {
                      return (
                        <MenuItem value={state.id}>{state.state}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>City*</Text>
                <FormControl fullWidth>
                  <Select
                    id="city_id"
                    name="city_id"
                    size="small"
                    color="primary"
                    value={values["city_id"]}
                    onChange={handleChange}
                    error={errors["city_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["city_id"]}
                  >
                    {city?.map((city) => {
                      return <MenuItem value={city.id}>{city.city}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </span>

            </CustomDiv>
              <FormControl >
                <RadioGroup row
                  id="address_type3"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="address_type3"
                  value={values["address_type3"]}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="address"
                    control={<Radio size="small" />}
                    className="!text-xs text-gray-500"
                    label="Same As Billing/Shipping Address"
                  />
                  <FormControlLabel
                    value="billing"
                    control={<Radio size="small" />}
                    className="!text-xs text-gray-500"
                    label="Only Shipping Address"
                  />
                </RadioGroup>
              </FormControl>
            <CustomButton
              disabled={isValid ? false : true}
              type="submit"
              className="!w-full !my-5 "
            >
              Save Address
            </CustomButton>
          </form>
        </Sheet>
      </Modal>


















      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={opens}
        onClose={() => setOpens(false)}
        className="flex justify-center h-5/6 !outline-none my-auto"
      >
        <Sheet
          variant="outlined"
          className="lg:w-1/2 w-11/12 !outline-none p-8 rounded"
        >

          <ModalClose variant="outlined" />
          <form onSubmit={handleSubmit} className="h-full">
            <Text className="my-3 text-xl font-semibold">
              Add Billing Address
            </Text>
            <CustomDiv className="grid gap-5 pr-1 overflow-y-auto lg:grid-cols-2 h-5/6 lg:pr-0">
              <span className="w-full">
                <Text>Name*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="name"
                  name="name"
                  value={values["name"]}
                  onChange={handleChange}
                  error={errors["name"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["name"]}
                />
              </span>
              <span className="w-full">
                <Text>Address Type*</Text>
                <FormControl fullWidth>
                  <Select
                    id="address_type"
                    name="address_type"
                    size="small"
                    defaultValue={address?.[0]?.address_type}
                    color="primary"
                    value={values["address_type"]}
                    onChange={handleChange}
                    error={errors["address_type"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["address_type"]}
                  >
                    <MenuItem value={"Home"}>Home</MenuItem>
                    <MenuItem value={"Office"}>Office</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>Contact Number*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="mobile_number"
                  name="mobile_number"
                  value={values["mobile_number"]}
                  onChange={handleChange}
                  error={errors["mobile_number"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["mobile_number"]}
                />
              </span>
              <span className="w-full">
                <Text>Flat/House No./Compnay Name*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="address"
                  name="address"
                  value={values["address"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["address"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["address"]}
                />
              </span>
              <span className="w-full">
                <Text>Area/Landmark*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="area"
                  name="area"
                  value={values["area"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["area"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["area"]}
                />
              </span>

              <span className="w-full">
                <Text>Street/Sector/Village*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="street_address"
                  name="street_address"
                  value={values["street_address"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["street_address"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["street_address"]}
                />
              </span>
              <span className="w-full">
                <Text>Pincode*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  id="pincode"
                  name="pincode"
                  value={values["pincode"]}
                  onChange={handleChange}
                  className="!w-full !rounded"
                  error={errors["pincode"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["pincode"]}
                />
              </span>
              <span className="w-full">
                <Text>Email*</Text>
                <TextField
                  type="text"
                  size="small"
                  color="primary"
                  className="!w-full !rounded"
                  id="email"
                  name="email"
                  value={values["email"]}
                  onChange={handleChange}
                  error={errors["email"] ? true : false}
                  FormHelperTextProps={{ className: "!text-red-500" }}
                  helperText={errors["email"]}
                />
              </span>

              <span className="w-full">
                <Text>Country*</Text>
                <FormControl fullWidth>
                  <Select
                    id="country_id"
                    size="small"
                    color="primary"
                    name="country_id"
                    value={values["country_id"]}
                    onChange={handleChange}
                    error={errors["country_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["country_id"]}
                  >
                    {country?.map((country) => {
                      return (
                        <MenuItem value={country.id}>
                          {country.country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>State*</Text>
                <FormControl fullWidth>
                  <Select
                    id="state_id"
                    name="state_id"
                    size="small"
                    color="primary"
                    value={values["state_id"]}
                    onChange={handleChange}
                    error={errors["state_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["state_id"]}
                  >
                    {state?.map((state) => {
                      return (
                        <MenuItem value={state.id}>{state.state}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>
              <span className="w-full">
                <Text>City*</Text>
                <FormControl fullWidth>
                  <Select
                    id="city_id"
                    name="city_id"
                    size="small"
                    color="primary"
                    value={values["city_id"]}
                    onChange={handleChange}
                    error={errors["city_id"] ? true : false}
                    FormHelperTextProps={{ className: "!text-red-500" }}
                    helperText={errors["city_id"]}
                  >
                    {city?.map((city) => {
                      return <MenuItem value={city.id}>{city.city}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </span>
            </CustomDiv>
            <FormControl >
                <RadioGroup row
                  id="address_type3"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="address_type3"
                  value={values["address_type3"]}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="address"
                    control={<Radio size="small" />}
                    className="!text-xs text-gray-500"
                    label="Same As Billing/Shipping Address"
                  />
                  <FormControlLabel
                    value="billing"
                    control={<Radio size="small" />}
                    className="!text-xs text-gray-500"
                    label="Only Shipping Address"
                  />
                </RadioGroup>
              </FormControl>
            <CustomButton
              disabled={isValid ? false : true}
              type="submit"
              className="!w-full !my-5 "
            >
              Save Address
            </CustomButton>
          </form>
        </Sheet>
      </Modal>
    </CustomDiv>
  );
};

export default SelectAddress;
