import { CircularProgress, Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../Shared/CustomButton";
import CustomDiv from "../../../Shared/CustomDiv";
import Text from "../../../Shared/Text";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { API_URLS } from "../../../Config/API_URLS";
import axiosInstance from "../../../Config/axios";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { checkPincodeFn } from "../../../Services/CheckPincode";
import { useDispatch } from "react-redux";
import { setThemeMode } from "../../../Redux/Actions/ThemeMode";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { HelpOutline } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  rounded: 2,
};

const ProductPage = () => {
  const { id, variant_id } = useParams();
  const [thumbnail, setThumbnail] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const [tab, setTab] = useState("Description");
  const [variantColor, setVariantColor] = useState(0);
  const [variantStorage, setVariantStorage] = useState(0);
  const [variant, setVariant] = useState("");
  const [pincode, setPincode] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [open1, setOpen1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
  };

  const navigate = useNavigate();

  const handleThaumbnailClick = (src) => {
    setThumbnail(src);
  };

  const productDetailData = () => {
    setIsLoading(true);
    const reqbody = new FormData();
    reqbody.append("product_id", id);
    reqbody.append("variant_id", variant_id);
    axiosInstance
      .post(
        `api/store/new-product-deatils/?variant_color_value_id=${variantColor}&variant_storage_value_id=${variantStorage}&other_variants_value_id=${variant}`,
        reqbody
      )
      .then((response) => {
        setIsLoading(false);
        setDetail(response.data.data[0]?.product_deatils[0]);

        setData(response.data.data[0]?.related_products);
        console.log(response.data.data[0])
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };



  const dispatch = useDispatch();
  const handleLogin = () => {
    dispatch(setThemeMode(true));
  };

  const client = useQueryClient();
  const addToCart = (event, variantId) => {
    event.stopPropagation();
    const reqBody = new FormData();
    reqBody.append("product_id", variantId);
    reqBody.append("add_quantity", 1);
    axiosInstance
      .post(API_URLS.addToCart, reqBody)
      .then((response) => {
        client.refetchQueries(["myCartList"]);
        enqueueSnackbar(response.data.status);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  useEffect(() => {
    productDetailData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [variantColor, variantStorage, id]);

  const emptyFunction = () => { };
  const { mutate } = useMutation(checkPincodeFn, {
    onSuccess: (response) => {
      response.data.response_code === 200
        ? setDelivery(
          `Delivery within ${response.data.delivery_time} in ${response.data.city_name}`
        )
        : emptyFunction();
    },
  });

  useEffect(() => {
    pincode.length === 6 ? mutate({ zipcode: pincode }) : emptyFunction();
  }, [pincode]);



  return isLoading ? (
    <>
      <CustomDiv className="flex h-[90vh] justify-center items-center">
        <CircularProgress size="lg" color="primary" />
      </CustomDiv>
    </>
  ) : (
    <CustomDiv className="flex flex-col overflow-x-hidden bg-gray-100  shadow px-[2%] lg:px-[3%] py-[1%] w-full">
      <CustomDiv className="flex flex-col w-full bg-white lg:flex-row ">
        <CustomDiv className="flex flex-col justify-center lg:w-[80%]">
          <div className="grid items-center justify-center w-full grid-cols-2 gap-5 p-5 ">
            <img
              src={
                detail?.product_variant_value_list?.[0]?.variant_image
              }
              alt=""
              className="h-[500px] w-[500px] rounded p-2"
            />

            {/* <div>
              {" "}
              <img
                alt=""
                src="https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dwbf7a5640/images/hi-res/2023-L3-CK1-60280409-J6-1.jpg?sw=1152&sh=1536"
                className="w-full h-fit"
              />
            </div>
            <div>
              {" "}
              <img
                alt=""
                src="https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dwe08b75f3/images/hi-res/2023-L3-CK1-60280409-J6-3.jpg?sw=1152&sh=1536"
                className="w-full h-fit"
              />
            </div>
            <div>
              {" "}
              <img
                alt=""
                src="https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw6f63ecb6/images/hi-res/2023-L3-CK1-60280409-J6-7.jpg?sw=1152&sh=1536"
                className="w-full h-fit"
              />
            </div>
            <div>
              {" "}
              <img
                alt=""
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmvu0iGKE4TwXlRGz8OFn079lMs-jKJTGFOg&usqp=CAU"
                className="w-full h-fit"
              />
            </div> */}
          </div>

          {/* <CustomDiv className="px-4 pb-3 lg:px-8">
            <Slider {...settings} className="home-slider">
              {detail?.product_variant_value_list?.[0]?.varients_multiple_image?.map(
                (item) => {
                  return (
                    <CustomDiv
                      className="cursor-pointer"
                      onClick={() => {
                        handleThaumbnailClick(item.variant_image);
                      }}
                    >
                      <img
                        src={item.variant_image}
                        alt=""
                        className={classNames(
                          "p-1 border border-zinc-200 ",
                          item.variant_image === thumbnail
                            ? "border-zinc-400"
                            : ""
                        )}
                      />
                    </CustomDiv>
                  );
                }
              )}
            </Slider>
          </CustomDiv> */}
        </CustomDiv>
        <Divider orientation="vertical" />

        <CustomDiv className="flex flex-col min-h-fit lg:w-[40%]">
          <CustomDiv className="flex flex-col w-full gap-3 p-5">
            <Text className="text-lg font-semibold lg:text-xl">
              {detail?.product_variant_value_list?.[0]?.variant_name}
            </Text>

            <Text>{detail?.sub_category}</Text>

            <span className="flex items-center gap-2 text-lg">
              <Text
                className={
                  detail?.product_variant_value_list?.[0]?.stock_sataus ===
                    "In-stock"
                    ? "text-green-500 text-lg font-semibold"
                    : "text-red-500 text-lg font-semibold"
                }
              >
                {detail?.product_variant_value_list?.[0]?.stock_sataus}
              </Text>

              <Text className="text-sm">(Product HSN : {detail?.HSN})</Text>
            </span>

            <Text className="uppercase">
              {detail?.variant_color?.[0]?.title}
            </Text>

            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.variant_color_value?.map((color) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.color_variant_values_id === color.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.color_variant_values_id === color.id
                        ? null
                        : setVariantColor(color.id)
                    }
                  >
                    <Text>{color.color}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>

            <Text className="uppercase">
              {detail?.variant_storage?.[0]?.title}
            </Text>
            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.variant_storage_value?.map((storage) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.storage_variant_values_id === storage.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.storage_variant_values_id === storage.id
                        ? null
                        : setVariantStorage(storage.id)
                    }
                  >
                    <Text>{storage.value}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>

            <Text className="uppercase">
              {detail?.other_variants?.[0]?.title}
            </Text>

            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.other_variants_value?.map((ram) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.other_variant_values_id === ram.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.other_variant_values_id === ram.id
                        ? null
                        : setVariant(ram.id)
                    }
                  >
                    <Text>{ram.value}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>

            <Text className="text-lg">
              MRP
              <span className="mx-1 line-through">
                ₹{detail?.product_variant_value_list?.[0]?.variant_price}
              </span>
              <span className="mx-1 text-xl font-bold">
                ₹{detail?.product_variant_value_list?.[0]?.actual_price}
              </span>
              <span className="mx-1 text-green-600">
                Save
                <span className="mx-1">
                  {Number(detail?.discount_percent)}%
                </span>
              </span>
            </Text>

            <Text>Inclusive of all taxes</Text>

            {/* <Text className="w-full p-2 px-4 bg-zinc-100">
              • <span className="text-green-700">FREE Delivery </span> on orders
              by Griffon over ₹499
            </Text> */}

            <CustomDiv className="flex flex-col items-center justify-around w-full gap-2 my-2 lg:flex-row lg:px-10">
              <CustomButton
                className="!rounded-md !w-72 lg:!w-full !bg-gray-700 !px-10 !py-2"
                onClick={(event) =>
                  localStorage.getItem("token")
                    ? addToCart(
                      event,
                      detail?.product_variant_value_list?.[0]?.variant_id
                    )
                    : handleLogin()
                }
              >
                Add To Cart
              </CustomButton>
            </CustomDiv>

            <div className="text-sm ">
              <p className="font-bold text-black text-md">BEST OFFERS </p>

              <p>
                Offer :
                {
                  detail?.product_variant_value_list?.[0]?.coupons_data?.[0]
                    ?.offer_tag
                }
              </p>
              <p>
                Minimum Purchase Upto :Rs.
                {
                  detail?.product_variant_value_list?.[0]?.coupons_data?.[0]
                    ?.purchase_upto
                }
              </p>
              <p>
                Valid From :
                {
                  detail?.product_variant_value_list?.[0]?.coupons_data?.[0]
                    ?.valid_from
                }
              </p>
              <p>
                Valid To :
                {
                  detail?.product_variant_value_list?.[0]?.coupons_data?.[0]
                    ?.valid_to
                }
              </p>

              {/* <button className="py-3 text-red-600">
                View Eligible Products
              </button>
              <p className="font-bold">
                Flat Rs 300 Cashback for select users Or UpTo Rs 500 Cashback on
                CRED pay UPI transactions.
              </p>
              <p>Min Spend Rs 1,000. Available only on Android Devices.</p>
              <button className="py-3 text-red-600">Terms & Condition</button>

              <button className="py-3 text-red-600">View Plan</button> */}
            </div>
          </CustomDiv>

          <CustomDiv className="flex flex-col items-center justify-between w-full gap-5 p-3 px-5 bg-gray-200 lg:flex-row">
            <span className="flex items-center gap-2">
              <div className="">
                {detail?.is_active_replacement || detail?.is_active_return ? (
                  <Text onClick={handleOpen}>
                    {detail?.is_active_replacement &&
                      detail?.time_period_replacement_policy}
                    <HelpOutline />
                    {detail?.is_active_return &&
                      detail?.time_period_return_replacement_policy}
                  </Text>
                ) : (
                  <Text onClick={handleOpen}>
                    No Return or Replacement Applicable <HelpOutline />
                  </Text>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box
                    sx={{ ...style, width: 600 }}
                    className="!border-0 outline-none"
                  >
                    {(detail?.is_active_replacement ||
                      detail?.is_active_return) && (
                        <>
                          <div className="flex justify-between p-2 bg-gray-300">
                            <p>Validity</p>
                            <p>Cover</p>
                            <p>Type Accepted</p>
                          </div>
                          <div className="flex justify-between p-2">
                            <p>{detail?.validity}</p>
                            <p>{detail?.covers}</p>
                            <p>{detail?.type_accepted}</p>
                          </div>
                        </>
                      )}
                    {detail?.is_active_replacement && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: detail?.replacement_policy,
                        }}
                      ></p>
                    )}
                    {detail?.is_active_return && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            detail?.is_active_return &&
                            detail?.return_and_refund_replacement_policy,
                        }}
                      ></p>
                    )}
                    {!(
                      detail?.is_active_replacement || detail?.is_active_return
                    ) && (
                        <>
                          <p>This product is not eligible for returns.</p>
                        </>
                      )}
                  </Box>
                </Modal>
              </div>
            </span>
            {/* )} */}
            <Text>Sold by : Griffon Pvt. Ltd.</Text>
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>

      <Text className="my-5 text-xl font-bold">Product Description</Text>
      <CustomDiv className="flex flex-col bg-white shadow">
        <CustomDiv className="flex gap-3 px-2">
          {["Description"].map((item) => {
            return (
              <Link onClick={() => setTab(item)} to="#">
                <Text
                  className={classNames(
                    "border-b-2 border-red-500 border-opacity-0 hover:border-opacity-100 p-2",
                    tab === item ? "!border-opacity-100 !text-red-500" : ""
                  )}
                  key={item.id}
                >
                  {item}
                </Text>
              </Link>
            );
          })}
        </CustomDiv>
        <Divider />
        <CustomDiv>
          {tab === "Description" ? (
            <Text className="p-5">{detail?.description}</Text>
          ) : tab === "Ingredients" ? (
            <Text className="p-5">
              Ingredients : Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dicta, mollitia! Veritatis repellat maiores blanditiis
              corporis, non maxime placeat soluta incidunt a suscipit fuga
              voluptatum ducimus architecto ipsum eveniet error deleniti?
            </Text>
          ) : (
            <Text className="p-5">
              How To Use : Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dicta, mollitia! Veritatis repellat maiores blanditiis
              corporis, non maxime placeat soluta incidunt a suscipit fuga
              voluptatum ducimus architecto ipsum eveniet error deleniti?
            </Text>
          )}
        </CustomDiv>
      </CustomDiv>
      <div></div>
      <CustomDiv className="my-5">
        <Text className="text-xl font-semibold">Related Products</Text>
        <CustomDiv className="flex w-full gap-4 py-1 my-5 overflow-x-auto">
          {data?.map((product) => {
            return (
              <CustomDiv
                className="rounded-md h-fit w-[250px] duration-200 shadow bg-white cursor-pointer "
                onClick={() => navigate(`/product/${product?.product_id}`)}
              >
                <span className="centerdiv">
                  {console.log("prduct", product.product_image)}
                  <img
                    src="https://35f18db08c1c471b5147cec159f69ebe.r2.cloudflarestorage.com/mstore/media/store_prod/medical-text-books-white-color-background-37073979.webp?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=efdb70b66a7d6723788bb2c7e0a90036%2F20241112%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241112T113506Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=43972ae4c8e43426b8f823850d2c3488f1b47687745f283885016c52de8c6304"
                    alt=""
                    className="relative h-52"
                  />
                </span>
                <CustomDiv className="flex flex-col justify-between p-4 space-y-2">
                  <CustomDiv className="space-y-2">
                    <Text className="text-lg font-semibold class-name">
                      {product.variant_name}
                    </Text>
                  </CustomDiv>
                  <Text className="text-sm uppercase">
                    {product.main_category}
                  </Text>
                  <CustomDiv className="flex items-center gap-1">
                    <Text className="font-bold">₹{product.price}</Text>
                    <Text className="text-xl font-bold"></Text>
                    <Text className="text-green-600">
                      {product.discount_percent !== "0.0"
                        ? `(${product.discount_percent?.split(".0")})% OFF`
                        : null}
                    </Text>
                  </CustomDiv>
                  <span className="flex gap-4">
                    <CustomButton
                      type="button"
                      className="!rounded w-full !bg-gray-700"
                      onClick={(event) =>
                        localStorage.getItem("token")
                          ? addToCart(event, product.variant_id)
                          : handleLogin()
                      }
                    >
                      Add To Cart
                    </CustomButton>
                  </span>
                </CustomDiv>
              </CustomDiv>
            );
          })}
        </CustomDiv>
      </CustomDiv>
    </CustomDiv>

    //     <>
    //     <div className='p-5 text-sm'>
    // <p className=''> Home | Bags | Cross Bags | <span className='font-bold'>Padlock Envelope Crossbody Bag</span></p>
    // </div>

    //    <div className='grid grid-cols-2'>
    //     <div className='flex flex-wrap w-full h-auto gap-3 p-2'>
    //         {
    //                 [
    //                     {
    //                         img:"https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw9406e1d7/images/hi-res/2022-L3-CK2-41220007-01-1.jpg?sw=1152&sh=1536",

    //                     },
    //                     {
    //                         img:"https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw9406e1d7/images/hi-res/2022-L3-CK2-41220007-01-1.jpg?sw=1152&sh=1536",
    //                     },
    //                     {
    //                         img:"https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw9406e1d7/images/hi-res/2022-L3-CK2-41220007-01-1.jpg?sw=1152&sh=1536",
    //                     },
    //                     {
    //                         img:"https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw9406e1d7/images/hi-res/2022-L3-CK2-41220007-01-1.jpg?sw=1152&sh=1536",
    //                     },
    //                     {
    //                         img:"https://www.charleskeith.in/dw/image/v2/BCWJ_PRD/on/demandware.static/-/Sites-in-products/default/dw9406e1d7/images/hi-res/2022-L3-CK2-41220007-01-1.jpg?sw=1152&sh=1536",
    //                     },
    //                 ].map((singleData,index)=>{
    //                     return <div className='w-[49%] py-2'>
    //                         <img className='img' alt="" src={singleData.img}/>
    //                     </div>
    //                 })
    //         }
    //     </div>

    //  <div className='p-10 '>
    //        <div>
    //         <h1 className='font-bold text-md '>Padlock Envelope Crossbody Bag - Chocolate</h1>
    //         <p className='text-[60%] text-gray-500'>Item No. CK2-80770605_CHOCO_S</p>
    //         <p className='font-bold'>₹7,499</p>
    //         <p className='mt-10'> Color: <span className='font-bold text-red-800 hover:underline'>Chocolate</span></p>
    //         </div>

    // <div className='space-x-5'>
    //     <button className='w-5 h-5 bg-gray-200 border-black rounded-full hover:border-2 //'></button>
    //         <button className='w-5 h-5 bg-red-900 border-black rounded-full hover:border-2 //'></button>
    //             <button className='w-5 h-5 bg-black border-black rounded-full hover:border-2 //'></button>

    // </div>

    // <div>
    // <p>Size : <span className='text-sm text-gray-500'>sm</span></p>
    // <button className='w-20 h-10 p-2 bg-gray-100 border-black rounded hover:border-2'>S</button>
    // </div>

    // <div className='m-auto'>
    // <button className='bg-[#333333] border-black hover:border-2 text-white rounded w-[100%]  h-10 mt-10'>Add to Card</button>
    // </div>

    // <div className='text-center '>

    //     <div>
    //         <button>
    //         {/* <GoShare/> */}
    //         </button>
    //     </div>
    //     <div className=''>
    //     <button className='text-sm text-black hover:border-2'>
    //       Share</button>
    //     </div>

    // </div>

    // <div>

    // </div>

    //     </div>

    //    </div>
    //     </>
  );
};

export default ProductPage;
