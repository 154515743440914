export const API_URLS = {
  login: "api/store/login/",
  verifyOTP: "api/store/verify-login-otp/",
  // productList: "api/store/product-list/",
  productList: "api/store/product-list-by-main-category-list/",
  productSortBy: "api/store/filter-product-list",
  productDetails: "api/store/new-product-deatils/",
  updateProfile: "api/store/update-profile/",
  profile: "api/store/my-profile/",
  myOrders: "api/store/my-order/",
  myWishList: "api/store/wishlist-list/",
  addToWishList: "api/store/add-to-wishlist/",
  removeWishList: "api/store/remove-from-wishlist/",
  addressList: "api/store/my-address-list/",
  cityList: "api/city-list/",
  stateList: "api/state-list/",
  countryList: "api/country-list/",
  addAddress: "api/store/add-new-address/",
  privacyPolicy: "api/store/privacy-policy/",
  contactUs: "api/store/contact-us/",
  writeReviw: "api/store/write-review/",
  addToCart: "api/store/add-to-cart/",
  cartList: "api/store/cart-list/",
  removeCartItem: "api/store/remove-from-cart/",
  increaseCart: "api/store/increase-product-quantity-from-cart/",
  decreaseCart: "api/store/decrease-product-quantity-from-cart/",
  paymentReceipt: "api/store/create-payment-receipt/",
  clearCart: "api/store/clear-cart/",
  checkOut: "api/store/order-checkout/",
  sliderList: "/api/store/slider-list/",
  updateAddress: "api/store/update-address/",
  deleteUpdate: "api/store/delete-address/",
  checkPincode: "api/store/order-delivery-time-by-city/",
  orderCancellation: "api/store/order-cancellation-section/",
  categoryList: "/api/store/overall-main-category-sub-category-list/",
  brandList: "api/store/brand-list/",
  allFilters: "api/store/all-filter-product-list",
  resonsForCancellation: "/api/store/reason-for-cancellation-list/",
  testimonial: "api/store/web-testimonials/",
  banner: "api/store/banner-list/",
  codCheckout: "api/store/order-checkout-cash-on-delivery/",
  invoiceDetail: "api/store/get-single-invoice-details/",
  invoiceList: "api/store/show-all-invoice-list/",
  //invoiceList: "api/store/get-all-order-data/",
  createInvoice: "api/store/create-invoice/",
  myWallet: "/api/store/my-wallet/",
  checkReferral: "api/search_check_referral_code/",
  checkUserReferral: "api/search_checkuser/",
  myCoupon: "/api/store/my-coupons/",
  returnResons: "/api/store/reason-for-return-list/",
  return: "api/store/order-return-section/",
  returnReplacement: "api/store/reason-for-replacement-list/",
  Store: "/api/store/store-deatils-favicon-logo-social-media-link/",
  enquiryDetails: "api/customer-inquery-details/",
  returnResons: "api/store/reason-for-return-list/",
  replacement: "api/store/order-replacement-section/",
  notifications: "api/multivendor-show-all-notification/",
  topRated: "api/store/show-top-rated-variants-data/",
  recentlyAdded: "api/store/show-recently-added-variants-data/",
  mostViewed: "api/store/show-mostviewed-product-variants-data/",


};
