import React, { useState } from "react";
import CustomDiv from "../CustomDiv";
import brandLogo from "../../Assets/logobook.png";
import SignIn from "../../Authentication/SignInPage";
import TemporaryDrawer from "./AddToCart";
import { useNavigate } from "react-router-dom";
import Text from "../Text";
import { useMutation, useQuery } from "react-query";
import { myWishList } from "../../Services/WishList";
import { Store } from "../../Services/Store";
import { API_URLS } from "../../Config/API_URLS";
import axiosInstance from "../../Config/axios";
import { useEffect } from "react";

import wishlistLogo from "../../Assets/wishlist.svg";
import defg from "../../Assets/defg.webp"
import {
  Autocomplete,
  Badge,
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowDownOutlined,
  MenuTwoTone,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import { categoryListFn } from "../../Services/CategoryList";
import { useDispatch } from "react-redux";
import { setThemeMode } from "../../Redux/Actions/ThemeMode";
import { productsFn } from "../../Services/Products";



const Headers = () => {
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState([]);
  const [store, setStore] = useState([]);
  const [spices, setSpices] = React.useState([]);
  const [search, setSearch] = useState(false);
  const [value, setValue] = useState("");
  const { mutate: allProducts } = useMutation(productsFn, {
    onSuccess: (response) => {
      setSpices(response.data.data.product_list);
    },
  });

  React.useEffect(
    () => {
      allProducts();
    },
    // eslint-disable-next-line
    [""]
  );

  const products = spices?.map((product) => {
    return {
      image: product.image,
      variant: product.variant_name,
      sub_category: product.sub_category,
      id: product.id,
    };
  });

  const handleClick = (id) => {
    setCollapse((collapse) => [...collapse, id]);
  };
  const handleClose = (id) => {
    setCollapse(collapse.filter((item) => item !== id));
  };
  const dispatch = useDispatch();
  const handleLogin = () => {
    dispatch(setThemeMode(true));
  };

  const navigate = useNavigate();
  const { data } = useQuery(["wishList"], () => myWishList(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: mainCategoryList } = useQuery(
    ["mainCategoryList"],
    () => categoryListFn(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const handleChange = (event) => {
    navigate(`/product/${event.currentTarget.lastChild.innerHTML}`);
    sessionStorage.clear();
    setOpen(false);
  };
  console.log(search);

  const Store = () => {

    axiosInstance
      .get(API_URLS.Store)
      .then((response) => {
        setStore(response.data.data.store_data)
      })
  }

  useEffect(() => {
    Store();
  }, [])

  return (
    <CustomDiv className="  sticky top-0 z-50 shadow">
      <CustomDiv className="flex flex-col justify-between items-center  z-50 shadow bg-white bg-opacity- text-black backdrop-blur w-full px-[4%] lg:px-[8%]">
        <CustomDiv className="flex items-center justify-between w-full">
          <span className="flex flex-col items-center ">
            <img
              src={brandLogo}
              alt=""
              className="relative h-[68px] cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-sm font-bold text-center text-black">Srishti Medical Book House</p>
          </span>
          <Autocomplete
  options={Array.isArray(products) ? products : []}
  autoHighlight
  size="small"
  open={search}
  className="Product lg:!block !hidden"
  freeSolo
  sx={{ width: 500 }}
  onClose={() => setSearch(false)}
  disableClearable
  getOptionLabel={(option) => option.variant || value || 'No label'}
  renderOption={(props, option) => (
    <Box
      key={option.id}
      {...props}
      onClick={() => {
        navigate(`/product/${option.id}`);
        setSearch(false);
      }}
    >
      <img
        loading="lazy"
        width="50"
        src={option.image}
        srcSet={option.image}
        alt=""
        className="pr-3"
      />
      <span className="flex flex-col">
        <span className="text-lg">{option.variant}</span>
        <span className="text-sm">{option.sub_category}</span>
      </span>
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Search Products"
      onFocus={() => setSearch(true)}
      onChange={(event) => {
        setValue(event.target.value);
        setSearch(true);
      }}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  )}
/>



          <span className="flex items-center gap-4 lg:gap-7">
            <SignIn />
            <Badge className="!bg-black"
              badgeContent={
                localStorage.getItem("token")
                  ? data?.data?.data?.wishlist_product_list?.length
                  : 0
              }
              color="primary"
            >
              <img
                alt=""
                src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJoia48EP0RFCp6JuIODLjXJMfe0qE3m58yDQ2CiLsl_fKh9-VPfOKW21z7tefUiY8cRs&usqp=CAU"}
                className="cursor-pointer h-7"
                onClick={() =>
                  localStorage.getItem("token")
                    ? navigate("/wish-list")
                    : handleLogin()
                }
              />
            </Badge>

            <TemporaryDrawer />

            <Badge className="!bg-black"

              color="primary"
            >
              <img alt="" src="https://www.shutterstock.com/image-vector/wallet-icon-vector-260nw-1377533453.jpg" className="cursor-pointer h-11"
                onClick={() =>
                  localStorage.getItem("token")
                    ? navigate("/wallet")
                    : handleLogin()
                }
              />
            </Badge>

            <IconButton onClick={() => setOpen(true)}>
              <MenuTwoTone className="!block lg:!hidden !text-black" />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
              <CustomDiv className="">
                <List className="!pr-0">
                  <span className="!p-4 flex justify-center">
                    <Autocomplete
                      options={products}
                      autoHighlight
                      size="small"
                      className="Product"
                      freeSolo
                      sx={{ width: 260 }}
                      onChange={handleChange}
                      disableClearable
                      getOptionLabel={(option) => option.variant}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="50"
                            src={option.image}
                            srcSet={option.image}
                            alt=""
                          />

                          <span className="flex flex-col">
                            <span className="text-lg">{option.variant}</span>
                            <span className="text-sm">
                              {option.sub_category}
                            </span>
                          </span>

                          <span id="variant_id" hidden>
                            {option.id}
                          </span>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Products"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </span>

                  <ListItemButton
                    onClick={() =>
                      navigate("/all-products", {
                        state: { from: "allproduct" },
                      })
                    }
                    className="!flex  !justify-between !w-full"
                  >Academics Books

                  </ListItemButton>
                  {mainCategoryList?.data?.data?.main_category_list?.map(
                    (product) => {
                      return (
                        <>
                          <ListItemButton
                            onClick={() => {
                              return (
                                <>
                                  {collapse.indexOf(product.id) === -1
                                    ? handleClick(product.id)
                                    : handleClose(product.id)}
                                </>
                              );
                            }}
                            className="!flex !justify-between !w-full"
                          >
                            <span>{product.category}</span>

                            {collapse ? <ExpandMore /> : <ExpandLess />}
                          </ListItemButton>
                          <Collapse
                            in={collapse.indexOf(product.id) !== -1}
                            className=""
                          >
                            <List>
                              {product?.sub_category_list?.map(
                                (subCategory) => {
                                  return (
                                    <ListItemButton
                                      className="!flex !pl-8 !justify-between !w-full"
                                      onClick={() =>
                                        navigate(`/all-products`, {
                                          state: {
                                            from: "subCategory",
                                            subCategory: subCategory.id,
                                          },
                                        })
                                      }
                                    >
                                      {subCategory.subcategory}
                                    </ListItemButton>
                                  );
                                }
                              )}
                            </List>
                          </Collapse>
                        </>
                      );
                    }
                  )}
                </List>
              </CustomDiv>
            </Drawer>
          </span>
        </CustomDiv>
      </CustomDiv>
      <CustomDiv className="hidden z-20 lg:flex gap-5 lg:py-4 px-2 mb-1 bg-slate-100 justify-center items-center">




        <div className="flex flex-col gap-2">
          <img src="https://m.media-amazon.com/images/I/719ACxhj0lL.jpg" alt='' className='object-cover w-16 h-16 border-2 border-gray-800 rounded-full' />
          <p className="text-sm text-center text-black">All Products</p>
        </div>
        {/*  <div className="flex gap-5 ">
          <p className='text-lg font-bold text-gray-800'>Academics Books</p>
          <p className='text-lg font-bold text-gray-800'>Fiction</p>
          <p className='text-lg font-bold text-gray-800'>Non Fiction</p>
          <p className='text-lg font-bold text-gray-800'>Children</p>
          <p className='text-lg font-bold text-gray-800'>Young Adults</p>
          <p className="text-lg font-bold text-gray-800">Comics & Graphic Novels</p>
          <p className="text-lg font-bold text-gray-800">Languages</p>
          <p className="text-lg font-bold text-gray-800">OFFERS</p>
        </div> */}

        {mainCategoryList?.data?.data?.main_category_list
          ?.slice(0, 6)
          ?.map((product) => {
            return (
              <div className="relative inline-block group">
                <Typography className="flex flex-col items-center justify-center gap-2">
                  <img src={product.image} alt='' className='object-scale-fill w-16 h-16 border-2 border-gray-800 rounded-full' />
                  <p className='flex items-center w-32 text-sm font-bold text-center text-gray-800'>{product.category}{/* <ArrowDropDownOutlined /> */}</p>
                </Typography>
                <ul className="absolute hidden w-96 p-2 z-50 shadow bg-white bg-opacity-80 backdrop-blur text-black border-t-4 border-[#306BDE] rounded-sm group-hover:grid group-hover:grid-cols-2">
                  {product?.sub_category_list?.map((subCategory) => {
                    return (
                      <li
                        key={subCategory.id}
                        className="flex flex-col gap-2 p-2 cursor-pointer hover:underline"
                        onClick={() =>
                          navigate(`/all-products`, {
                            state: {
                              from: "subCategory",
                              subCategory: subCategory.id,
                            },
                          })
                        }
                      >
                        {subCategory.subcategory}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </CustomDiv>

    </CustomDiv>
  )
}

export default Headers;