import React from "react";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import { useQuery } from "react-query";
import { myWalletFn } from "../../Services/MyWallet";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Wallet = () => {
  const { data } = useQuery(["myWallet"], () => myWalletFn());
  const account = data?.data?.data?.wallet?.[0];
  const accountHistory = data?.data?.data?.wallet_history;

  return (
    <CustomDiv className="flex flex-col gap-4 bg-gray-100 px-[8%] py-6">
      <CustomDiv className="flex flex-col">
        <CustomDiv className="flex lg:flex-row flex-col gap-3">
          <CustomDiv className="p-1 flex flex-col lg:justify-center lg:w-1/4 lg:items-center bg-black text-white !rounded-lg">
            <Text className="text-xl font-bold">Current Balance</Text>
            <Text className="text-2xl font-extrabold">{account?.balance}</Text>
          </CustomDiv>
          <CustomDiv className="flex flex-col gap-3 justify-center">
            <CustomDiv className="flex gap-5 bg-white p-3 px-10 items-center !rounded-lg">
              <img
                src="https://pub-8de1a28f49c04a568b19557f02b6129b.r2.dev/Assets/income.svg"
                alt=""
                className="h-10"
              />
              <span className="flex flex-col">
                <Text className="text-lg font-bold">Income</Text>
                <Text className="text-lg font-bold">
                  {account?.income_balance}
                </Text>
              </span>
            </CustomDiv>
            <CustomDiv className="flex gap-5 bg-white p-3 px-10 items-center !rounded-lg">
              <img
                src="https://pub-8de1a28f49c04a568b19557f02b6129b.r2.dev/Assets/spend.svg"
                alt=""
                className="h-10"
              />
              <span className="flex flex-col">
                <Text className="text-lg font-bold">Spend</Text>
                <Text className="text-lg font-bold">
                  {account?.spend_balance === null ? 0 : account?.spend_balance}
                </Text>
              </span>
            </CustomDiv>
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>

      <CustomDiv className=" bg-white !rounded-lg">
        <CustomDiv className="flex justify-between p-5 bg-black text-white rounded-t-lg">
          <Text className="text-xl font-semibold">Last Activity</Text>
          <Text>View All</Text>
        </CustomDiv>
        <Divider />
        <TableContainer className="min-h-[80vh]">
          <Table>
            <TableHead className="flex whitespace-nowrap !bg-blue-200 !text-xs">
              <TableRow>
                <TableCell className="!font-bold">Data and Time</TableCell>
                <TableCell className="!font-bold">Transaction ID</TableCell>
                <TableCell className="!font-bold">Previous Amount</TableCell>
                <TableCell className="!font-bold">Transaction Amount</TableCell>
                <TableCell className="!font-bold">Remaining Amount</TableCell>
                <TableCell className="!font-bold">Transaction Type</TableCell>
                <TableCell className="!font-bold">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            
              {accountHistory?.map((acc) => {
                return (
                  <TableRow>
                    <TableCell>{acc.date.slice(0, 19)}</TableCell>
                    <TableCell>{acc.transaction_id}</TableCell>
                    <TableCell>{acc.previous_amount}</TableCell>
                    <TableCell>
                      {acc.transaction_type === "CREDIT"
                        ? `+${acc.transaction_amount}`
                        : `-${acc.transaction_amount}`}
                    </TableCell>
                    <TableCell>{acc.remaining_amount}</TableCell>
                    <TableCell>{acc.transaction_type}</TableCell>
                    <TableCell>{acc.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomDiv>
    </CustomDiv>
  );
};

export default Wallet;
