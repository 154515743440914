import React from "react";
import CustomDiv from "../../Shared/CustomDiv";
import login from "../../Assets/login.png";
import CustomTextField from "../../Shared/CustomTextField";
import CustomButton from "../../Shared/CustomButton";
import Text from "../../Shared/Text";
import { Chip, Divider } from "@mui/joy";
import griffon from "../../Assets/logobook.png";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import brandLogo from "../../Assets/logobook.png";

const SignIn = () => {
  const handleClick = () => { };
  return (
    <Paper className="flex h-screen bg-white lg:flex-row flex-col w-full !rounded-none">
      <CustomDiv className="flex p-12 lg:w-1/2">
        <img src={brandLogo} alt="" />
      </CustomDiv>
      <Divider orientation="vertical" />
      <CustomDiv className="flex-col centerdiv lg:w-1/2">
        <form action="" className="flex-col w-full centerdiv">
          <img src={brandLogo} alt="" className="w-1/3" />
          <Text className="mb-5 text-3xl font-bold">Sign In</Text>
          <CustomTextField
            placeholder="Email"
            color="primary"
            className="lg:!w-1/2 !rounded mb-5 w-5/6"
          />
          <span className="w-5/6 lg:w-1/2">
            <CustomTextField
              placeholder="Password"
              color="primary"
              className="w-full !rounded"
            />
            <Text className="mb-5 cursor-pointer text-end hover:text-blue-600">
              Forget Password?
            </Text>
          </span>

          <CustomButton
            color="primary"
            className="!mb-4 !rounded lg:!w-1/2 w-5/6"
          >
            Sign In
          </CustomButton>

          <div className="flex items-center pt-4 space-x-1">
            <div className="flex-1 h-px bg-gray-300 sm:w-16"></div>
            <Text className="px-3 !text-black text-sm">Login with social accounts</Text>
            <div className="flex-1 h-px bg-gray-300 sm:w-16"></div>
          </div>
          <div className="flex justify-center my-3 space-x-4">
            <Chip
              variant="outlined"
              className="!rounded !px-1.5"
              onClick={() => handleClick()}
              startDecorator={
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/588px-Google_%22G%22_Logo.svg.png?20230305195327"
                  alt=""
                  className="w-5"
                />
              }
            >
              Login with Google
            </Chip>
            <Chip
              variant="outlined"
              className="!rounded !px-1.5"
              onClick={() => handleClick()}
              startDecorator={
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Facebook_Home_logo_old.svg/2048px-Facebook_Home_logo_old.svg.png"
                  alt=""
                  className="w-5"
                />
              }
            >
              Login with Facebook
            </Chip>
          </div>
          <Text className="text-xs text-center text-gray-600 sm:px-6">
            Don't have an account?
            <Link
              to="/signup"
              rel="noopener noreferrer"
              className="mx-1 underline"
            >
              Sign up
            </Link>
          </Text>
        </form>
      </CustomDiv>
    </Paper>
  );
};

export default SignIn;
