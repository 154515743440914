import React from "react";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import { useQuery } from "react-query";
import { myCouponFn } from "../../Services/Coupon";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Coupon = () => {
  const { data } = useQuery(["Coupon"], () => myCouponFn());
  const coupon = data?.data?.data?.[0];
//   const accountHistory = data?.data?.data?.wallet_history;
  return (
    <CustomDiv className="flex flex-col gap-4  px-[8%] py-[1%]">
     <p>Offer</p>
     <p>1- UpTo <span className="text-green-800">{coupon?.offer_tag}</span><span> Purchase Rs. <span className="text-red-800">{coupon?.purchase_upto}</span></span><span> Code: <span className="text-blue-800">{coupon?.coupon_code}</span></span></p>
    </CustomDiv>
  );
};

export default Coupon;
